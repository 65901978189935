$pathspot-primary: #025c71;
$pathspot-primary-100: #ffffff;
$pathspot-primary-95: #e6faff;
$pathspot-primary-90: #cdf5fe;
$pathspot-primary-85: #b4f0fe;
$pathspot-primary-80: #9bebfd;
$pathspot-primary-75: #81e6fd;
$pathspot-primary-70: #68e1fd;
$pathspot-primary-65: #4fddfc;
$pathspot-primary-60: #36d8fc;
$pathspot-primary-55: #1dd3fc;
$pathspot-primary-50: #04cefb;
$pathspot-primary-45: #03b9e2;
$pathspot-primary-40: #03a5c9;
$pathspot-primary-35: #0390b0;
$pathspot-primary-30: #027b97;
$pathspot-primary-25: #02677e;
$pathspot-primary-23: #025c71;
$pathspot-primary-20: #025264;
$pathspot-primary-15: #013e4b;
$pathspot-primary-10: #012932;
$pathspot-primary-5: #001519;
$pathspot-primary-0: #000000;
$pathspot-primary-05p: rgba(2, 92, 113, 0.5);
//secondary
$pathspot-secondary: #00a199;
$pathspot-secondary-100: #ffffff;
$pathspot-secondary-95: #e6fffe;
$pathspot-secondary-90: #ccfffc;
$pathspot-secondary-85: #b3fffb;
$pathspot-secondary-80: #99fffa;
$pathspot-secondary-75: #80fff9;
$pathspot-secondary-70: #66fff7;
$pathspot-secondary-65: #4dfff6;
$pathspot-secondary-60: #33fff5;
$pathspot-secondary-55: #1afff4;
$pathspot-secondary-50: #00fff2;
$pathspot-secondary-45: #00e6da;
$pathspot-secondary-40: #00ccc2;
$pathspot-secondary-35: #00b3aa;
$pathspot-secondary-32: #00a199;
$pathspot-secondary-30: #009991;
$pathspot-secondary-25: #008079;
$pathspot-secondary-20: #006661;
$pathspot-secondary-15: #004d49;
$pathspot-secondary-10: #003330;
$pathspot-secondary-5: #001a18;
$pathspot-secondary-0: #000000;
//Pathspot Green
$pathspot-green: #a4cf59;
$pathspot-green-100: #ffffff;
$pathspot-green-95: #f4f9eb;
$pathspot-green-90: #e9f4d7;
$pathspot-green-85: #deeec4;
$pathspot-green-80: #d3e8b0;
$pathspot-green-75: #c9e29c;
$pathspot-green-70: #bedd88;
$pathspot-green-65: #b3d775;
$pathspot-green-60: #a8d161;
$pathspot-green-58: #a4cf59;
$pathspot-green-55: #9dcb4d;
$pathspot-green-50: #92c639;
$pathspot-green-45: #84b234;
$pathspot-green-40: #759e2e;
$pathspot-green-35: #668a28;
$pathspot-green-30: #587722;
$pathspot-green-25: #49631d;
$pathspot-green-20: #3a4f17;
$pathspot-green-15: #2c3b11;
$pathspot-green-10: #1d280b;
$pathspot-green-5: #0f1406;
$pathspot-green-0: #000000;
//Pathspot Lavender
$pathspot-lavender: #a55095;
$pathspot-lavender-100: #ffffff;
$pathspot-lavender-95: #f7eef5;
$pathspot-lavender-90: #eeddeb;
$pathspot-lavender-85: #e6cbe1;
$pathspot-lavender-80: #debad7;
$pathspot-lavender-75: #d6a9cd;
$pathspot-lavender-70: #cd98c3;
$pathspot-lavender-65: #c587ba;
$pathspot-lavender-60: #bd75b0;
$pathspot-lavender-55: #b464a6;
$pathspot-lavender-50: #ac539c;
$pathspot-lavender-48: #a55095;
$pathspot-lavender-45: #9b4b8c;
$pathspot-lavender-40: #8a427d;
$pathspot-lavender-35: #783a6d;
$pathspot-lavender-30: #67325d;
$pathspot-lavender-25: #56294e;
$pathspot-lavender-20: #45213e;
$pathspot-lavender-15: #34192f;
$pathspot-lavender-10: #22111f;
$pathspot-lavender-5: #110810;
$pathspot-lavender-0: #000000;
//Pathspot Red
$pathspot-red: #ff4136;
$pathspot-red-100: #ffffff;
$pathspot-red-95: #ffe7e6;
$pathspot-red-90: #ffcfcc;
$pathspot-red-85: #ffb6b3;
$pathspot-red-80: #ff9e99;
$pathspot-red-75: #ff8680;
$pathspot-red-70: #ff6e66;
$pathspot-red-65: #ff554d;
$pathspot-red-61: #ff4136;
$pathspot-red-60: #ff3d33;
$pathspot-red-55: #ff251a;
$pathspot-red-50: #ff0d00;
$pathspot-red-45: #e60b00;
$pathspot-red-40: #cc0a00;
$pathspot-red-35: #b30900;
$pathspot-red-30: #990800;
$pathspot-red-25: #800600;
$pathspot-red-20: #660500;
$pathspot-red-15: #4d0400;
$pathspot-red-10: #330300;
$pathspot-red-5: #1a0100;
$pathspot-red-0: #000000;
//Pathspot Grey
$pathspot-gray: #6e7b91;
$pathspot-gray-100: #ffffff;
$pathspot-gray-95: #f0f2f4;
$pathspot-gray-90: #e2e5e9;
$pathspot-gray-85: #d3d7de;
$pathspot-gray-80: #c5cad3;
$pathspot-gray-75: #b6bdc8;
$pathspot-gray-70: #a8b0bd;
$pathspot-gray-65: #99a2b2;
$pathspot-gray-60: #8b95a7;
$pathspot-gray-55: #7c889c;
$pathspot-gray-50: #6e7b91;
$pathspot-gray-45: #636f83;
$pathspot-gray-40: #586274;
$pathspot-gray-35: #4d5666;
$pathspot-gray-30: #424a57;
$pathspot-gray-25: #373d49;
$pathspot-gray-20: #2c313a;
$pathspot-gray-15: #21252c;
$pathspot-gray-10: #16191d;
$pathspot-gray-5: #0b0c0f;
$pathspot-gray-0: #000000;
//Burnt Orange
$burnt-orange: #996300;
$burnt-orange-100: #ffffff;
$burnt-orange-95: #fff6e6;
$burnt-orange-90: #ffedcc;
$burnt-orange-85: #ffe4b3;
$burnt-orange-80: #ffdb99;
$burnt-orange-75: #ffd280;
$burnt-orange-70: #ffc966;
$burnt-orange-65: #ffc14d;
$burnt-orange-60: #ffb833;
$burnt-orange-55: #ffaf1a;
$burnt-orange-50: #ffa600;
$burnt-orange-45: #e69500;
$burnt-orange-40: #cc8500;
$burnt-orange-35: #b37400;
$burnt-orange-34: #ad7100;
$burnt-orange-30: #996300;
$burnt-orange-25: #805300;
$burnt-orange-20: #664200;
$burnt-orange-15: #4d3200;
$burnt-orange-10: #332100;
$burnt-orange-5: #1a1100;
$burnt-orange-0: #000000;
//Duke Blue
$duke-blue: #003087;
$duke-blue-100: #ffffff;
$duke-blue-95: #e6eeff;
$duke-blue-90: #ccdeff;
$duke-blue-85: #b3cdff;
$duke-blue-80: #99bdff;
$duke-blue-75: #80acff;
$duke-blue-70: #669cff;
$duke-blue-65: #4d8bff;
$duke-blue-60: #337aff;
$duke-blue-55: #1a6aff;
$duke-blue-50: #0059ff;
$duke-blue-45: #0050e6;
$duke-blue-40: #0047cc;
$duke-blue-35: #003eb3;
$duke-blue-30: #003699;
$duke-blue-26: #003087;
$duke-blue-25: #002d80;
$duke-blue-20: #002466;
$duke-blue-15: #001b4d;
$duke-blue-10: #001233;
$duke-blue-5: #00091a;
$duke-blue-0: #000000;
//Texas Orang
$texas-orange: #bf5700;
$texas-orange-100: #ffffff;
$texas-orange-95: #fff1e6;
$texas-orange-90: #ffe3cc;
$texas-orange-85: #ffd5b3;
$texas-orange-80: #ffc799;
$texas-orange-75: #ffb980;
$texas-orange-70: #ffab66;
$texas-orange-65: #ff9d4d;
$texas-orange-60: #ff8f33;
$texas-orange-55: #ff811a;
$texas-orange-50: #ff7300;
$texas-orange-45: #e66700;
$texas-orange-40: #cc5c00;
$texas-orange-37: #bf5700;
$texas-orange-35: #b35000;
$texas-orange-30: #994500;
$texas-orange-25: #803900;
$texas-orange-20: #662e00;
$texas-orange-15: #4d2200;
$texas-orange-10: #331700;
$texas-orange-5: #1a0b00;
$texas-orange-0: #000000;
//Grass Green
$grass-green: #73a24e;
$grass-green-100: #ffffff;
$grass-green-95: #f2f7ee;
$grass-green-90: #e4eedd;
$grass-green-85: #d7e6cb;
$grass-green-80: #cadeba;
$grass-green-75: #bcd6a9;
$grass-green-70: #afcd98;
$grass-green-65: #a2c587;
$grass-green-60: #94bd75;
$grass-green-55: #87b464;
$grass-green-50: #7aac53;
$grass-green-47: #73a24e;
$grass-green-45: #6d9b4b;
$grass-green-40: #618a42;
$grass-green-35: #55783a;
$grass-green-30: #496732;
$grass-green-25: #3d5629;
$grass-green-20: #314521;
$grass-green-15: #243419;
$grass-green-10: #182211;
$grass-green-5: #0c1108;
$grass-green-0: #000000;
//Slate Gray
$slate-gray: #596673;
$slate-gray-100: #ffffff;
$slate-gray-95: #f1f2f4;
$slate-gray-90: #e2e6e9;
$slate-gray-85: #d4d9de;
$slate-gray-80: #c5ccd3;
$slate-gray-75: #b7bfc8;
$slate-gray-70: #a9b3bc;
$slate-gray-65: #9aa6b1;
$slate-gray-60: #8c99a6;
$slate-gray-55: #7d8c9b;
$slate-gray-50: #708090;
$slate-gray-45: #647382;
$slate-gray-40: #596673;
$slate-gray-35: #4e5965;
$slate-gray-30: #434d56;
$slate-gray-25: #374048;
$slate-gray-20: #2c333a;
$slate-gray-15: #21262b;
$slate-gray-10: #161a1d;
$slate-gray-5: #0b0d0e;
$slate-gray-0: #000000;
//Block Blue
$block-blue: #0080b3;
$block-blue-100: #ffffff;
$block-blue-95: #e6f8ff;
$block-blue-90: #ccf1ff;
$block-blue-85: #b3e9ff;
$block-blue-80: #99e2ff;
$block-blue-77: #89ddff;
$block-blue-75: #80dbff;
$block-blue-70: #66d4ff;
$block-blue-65: #4dccff;
$block-blue-60: #33c5ff;
$block-blue-55: #1abeff;
$block-blue-50: #00b7ff;
$block-blue-45: #00a4e6;
$block-blue-40: #0092cc;
$block-blue-35: #0080b3;
$block-blue-30: #006e99;
$block-blue-25: #005b80;
$block-blue-20: #004966;
$block-blue-15: #00374d;
$block-blue-10: #002533;
$block-blue-5: #00121a;
$block-blue-0: #000000;
//Numeric Orange
$numeric-orange: #f2420d;
$numeric-orange-100: #ffffff;
$numeric-orange-95: #feece7;
$numeric-orange-90: #fcd9cf;
$numeric-orange-85: #fbc6b6;
$numeric-orange-80: #fab49e;
$numeric-orange-75: #f9a186;
$numeric-orange-70: #f78c6c;
$numeric-orange-65: #f67b55;
$numeric-orange-60: #f5683d;
$numeric-orange-55: #f45525;
$numeric-orange-50: #f2420d;
$numeric-orange-45: #da3c0b;
$numeric-orange-40: #c2350a;
$numeric-orange-35: #aa2e09;
$numeric-orange-30: #912808;
$numeric-orange-25: #792106;
$numeric-orange-20: #611b05;
$numeric-orange-15: #491404;
$numeric-orange-10: #300d03;
$numeric-orange-5: #180701;
$numeric-orange-0: #000000;
//Variable Red
$variable-red: #e71823;
$variable-red-100: #ffffff;
$variable-red-95: #fde8e9;
$variable-red-90: #fad1d3;
$variable-red-85: #f8babd;
$variable-red-80: #f5a3a7;
$variable-red-75: #f38c91;
$variable-red-70: #f0757b;
$variable-red-69: #f07178;
$variable-red-65: #ee5d65;
$variable-red-60: #ec464f;
$variable-red-55: #e92f39;
$variable-red-50: #e71823;
$variable-red-45: #d0161f;
$variable-red-40: #b9131c;
$variable-red-35: #a21118;
$variable-red-30: #8a0f15;
$variable-red-25: #730c11;
$variable-red-20: #5c0a0e;
$variable-red-15: #45070a;
$variable-red-10: #2e0507;
$variable-red-5: #170203;
$variable-red-0: #000000;
//Banana Yellow
$banana-yellow: #fac905;
$banana-yellow-100: #ffffff;
$banana-yellow-95: #fefae6;
$banana-yellow-90: #fef4cd;
$banana-yellow-85: #fdefb4;
$banana-yellow-80: #fde99b;
$banana-yellow-75: #fce482;
$banana-yellow-70: #fcde67;
$banana-yellow-65: #fbd950;
$banana-yellow-60: #fbd437;
$banana-yellow-55: #face1e;
$banana-yellow-50: #fac905;
$banana-yellow-45: #e1b505;
$banana-yellow-40: #c8a104;
$banana-yellow-35: #af8d04;
$banana-yellow-30: #967903;
$banana-yellow-25: #7d6403;
$banana-yellow-20: #645002;
$banana-yellow-15: #4b3c02;
$banana-yellow-10: #322801;
$banana-yellow-5: #191401;
$banana-yellow-0: #000000;
//Miscellaneous
$mint-green: #7fdbca;
$pastel-green: #bcf0c0;
$vibrant-purple: #c792ea;
$pastel-yellow: #f7ecb5;
$vibrant-blue: #00bff9;
$vibrant-pink: #ec9cd2;
$pastel-blue: #87aff4;
$primary: #025c71;
$success: #00a29b;
$active: #2eb85c;
$inactive: #e55353;
$secondary: #00a29b;
$complete: #00a29b;
$warning: #f9b115;
$ready: #a4cf59;
$info: #a55095;
$error: #a55095;
$danger: #f34545;
$primary-dark: #025c71;
$pathspot-blue: #025c71;
$pathspot-blue-50: #509cad;
$pathspot-teal: #00a29b;
$pathspot-teal-50: #72b6b2;
$pathspot-light-green: #a4cf59;
$pathspot-lavender: #a55095;
$pathspot-lavender-50: #d492c8;
$pathspot-gray: #636f83;
$pathspot-gray-dark: #515967;
$pathspot-gray-light: #818a98;
$pathspot-white: #ffffff;
$pathspot-off-white: #f4f3ee;
$pathspot-bg-white: #cfd7e3;
$pathspot-black: #000000;
$pathspot-red-dark: #8d1928;
$pathspot-red-light: #f34545;
$coreui-gray-text: #5c6873;
$coreui-group-gray: #f0f3f5;
//Sensors
$coreuiInputText: #5c6873;
$sensor-red-alt: #fa5961;
$sensor-red-alt-background: #f8aaae;
$sensor-red: rgb(202, 44, 39);
$sensor-red-background: rgb(215, 135, 139);
$sensor-green: rgb(24, 200, 53);
$sensor-green-background: rgb(154, 237, 185);
$sensor-warn: rgb(237, 142, 14);
$sensor-warn-background: rgb(246, 224, 135);
$sensor-grayed: rgb(64, 73, 73);
$sensor-grayed-background: rgb(167, 174, 174);
//Temperature Data
$temperature-data: 'rgba(255, 99, 132)';
$temperature-data-background: 'rgba(255, 99, 132, 0.5)';
$humidity-data: 'rgb(17, 119, 133)';
$humidity-data-background: 'rgb(17, 119, 133, 0.5)';
$critical-threshold: 'rgb(231, 76, 60, 1)';
$warning-threshold: 'rgb(241, 196, 15, 1)';
//Pathspot Misc
$pathspot-grey: #636f83;
$pathspot-grey-9: #999999;
$pathspot-grey-dark: #515967;
$pathspot-grey-light: #818a98;
$pathspot-white: #ffffff;
$pathspot-off-white: #f4f3ee;
$pathspot-bg-white: #cfd7e3;
$pathspot-black: #000000;
$pathspot-red: #8d1928;
$table-border-blue: #129ed189;
$default-white: #ebedef;
$pathspot-blue-150: #04252d;
$pathspot-red-light: #f34545;
$coreui-gray-text: #5c6873;
$default-white: #ebedef;
$review: #999999;
//Miscellaneous
$coreui-sidebar-brand: #2a3649;
$root-sk-text: #333;
$form-placeholder: #73818f;
$form-grayed-out: #e4e7ea;
$slate-blue: #6a5acd;
$darker-slate-blue: #1d3c54;
$dark-slate-blue: #2a5678;
$medium-slate-blue: #3872a0;
$light-slate-blue: #6aa0cb;
$lighter-slate-blue: #b3cee4;

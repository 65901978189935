@import './colors.scss';
@import './sizing.scss';

$primary: #025c71;
$success: #00a29b;
$warning: #a4cf59;
$info: #a55095;
$danger: #681823;
$error: #681823;
$ready: #a4cf59;

$pathspot-blue: #025c71;
$pathspot-blue-50: #509cad;
$pathspot-teal: #00a29b;
$pathspot-teal-50: #72b6b2;
$pathspot-light-green: #a4cf59;
$pathspot-lavender: #a55095;
$pathspot-lavender-50: #d492c8;
$pathspot-grey: #636f83;
$pathspot-grey-9: #999999;
$pathspot-grey-dark: #515967;
$pathspot-grey-light: #818a98;
$pathspot-white: #ffffff;
$pathspot-off-white: #f4f3ee;
$pathspot-bg-white: #cfd7e3;
$pathspot-black: #000000;
$pathspot-red: #8d1928;
$table-border-blue: #129ed189;
// $default-white: #ebedef;
$default-white: #ebedef;
$review: #999999;

// Here you can add other styles
button {
  outline: none !important;
}

iframe {
  border: none;
}

input:focus,
input:active {
  outline: 0 none !important;
}

input[type='checkbox']:focus {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}
.modal-buttons-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.my-sidebar {
  display: 0 !important;
}
.my-sidebar {
  // color: $pathspot-secondary;
  .c-sidebar-nav-item {
    :hover {
      background-color: #00a29b;
    }
  }
  .c-sidebar-nav-dropdown {
    :hover {
      background-color: #00a29b;
    }
  }
  background-color: $pathspot-primary;
  .c-sidebar-nav-dropdown.c-show {
    :hover {
      background: transparent;
    }

    .c-sidebar-nav-dropdown-items {
      .c-sidebar-nav-item {
        :hover {
          background-color: #00a29b;
        }
      }
    }
  }
  .c-sidebar-nav-dropdown {
    .c-show {
      :hover {
        background: transparent;
      }
    }
    .c-sidebar-nav-item {
      .c-sidebar-nav-link {
        padding-left: 75px;
      }
    }
  }
}
// .c-sidebar-nav-item::before {
//   :hover {
//     background-color: #00a29b;
//   }
// }a
// .pb-35rem {
//     padding-bottom: 4.5rem !important;
// }
.coreui-margin {
  // color: #5c6873 !important
  color: $pathspot-grey !important;

  font-size: 14px !important;
  font-weight: normal !important;
  // padding: 0.75rem 1.25rem !important;
  margin-right: -15px !important;
  margin-left: -15px !important;
}
.c-sidebar-brand {
  min-height: 104px;
}
.c-sidebar .c-sidebar-brand {
  color: #fff;
  background: $pathspot-white;
  /* background: rgba(0, 0, 21, 0.2); */
}
.c-switch-label .c-switch-slider::before,
.c-switch-label .c-switch-slider::before {
  z-index: 0 !important;
}
.text-left {
  text-align: left;
}
.worker-row-options {
  position: relative !important;
  left: 40px !important;
  top: -26px !important;
}
.worker-row-number {
  position: absolute !important;
  left: 25px;
  font-size: 80%;
  padding: 2px;
  margin-top: 6px;
}
.subcard-group {
  margin-left: -15px;
  margin-right: -15px;
  width: 100%;
}
.subcard-card {
  margin-left: -15px;
  margin-right: -15px !important;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
.subcard-group-nm {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-right: -15px;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  // resize: both;
}
.subcard-card-nm {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  // resize: both;
}
.subcard-group-footer-icon {
  margin-left: -15px;
  box-sizing: border-box;
  width: 100%;
}
.subcard-group-header {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-left: -10px;
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  color: #5c6873;
  background-color: #f0f3f5;
}
.subcard-header-text {
  font-size: 14px !important;
  font-weight: bold;
}
.subcard-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  border-bottom: 1px solid;
  background-color: #fff;
  border-color: #d8dbe0;
  word-wrap: break-word;
  background-color: $coreui-group-gray !important;
}
.subcard-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.subcard-group-header-tag {
  background-color: #f0f3f5;
}
.react-grid-cell-item {
  color: $coreuiInputText;
  background-color: inherit;
  padding-left: 0.5rem;
  margin-left: 0.25rem;
  text-align: left;
  font-size: 14px;
  vertical-align: middle;
  // align-content: center;
  .react-grid-table {
    .react-grid-header-item {
      // border: 1px solid #025c71;
      // border-radius 0.25rem;
      border-bottom: 0px double $table-border-blue;
      margin-bottom: 3px;
      padding-right: 2px;
      margin-right: 2px;
    }
  }
}
.react-grid-header-cell-item {
  color: #3c4b64;
  background-color: inherit;
  padding-left: 2px;
  margin-left: 2px;
  text-align: left;
  font-size: 16px;
  vertical-align: middle;
  // align-content: center;
}
.react-grid-item {
  color: #636f83;
  background-color: inherit;
  padding: 0px;
  margin-left: 0px;
  margin-top: 0.25rem;
  border-radius: 0.25rem;
  text-align: left;
  font-size: 1rem;
  vertical-align: middle;
  align-content: center;
}
.react-grid-item-fixed {
  color: #636f83;
  background-color: inherit;
  padding: 0px;
  margin-left: 0px;
  margin-top: 0.25rem;
  border-radius: 0.25rem;
  text-align: left;
  font-size: 1rem;
  vertical-align: middle;
  align-content: center;
  border-bottom: 6px double $table-border-blue;
  margin-bottom: 6px;
}
.react-grid-header-item {
  // border: 1px solid #025c71;
  // border-radius: 0.25rem;
  border-bottom: 6px double $table-border-blue;
  margin-bottom: 6px;
  font-size: 1rem;
}

//mockup
.react-grid-item.react-draggable.cssTransforms.react-resizable {
  border-radius: 0.5rem !important;
  border: 1px dotted #025c71 !important;
  background-color: $pathspot-white !important;
  box-sizing: border-box;
  white-space: pre-wrap;
  // margin-left: -15px;
  // margin-right: -15px;
  cursor: pointer;
  padding: 0;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100% !important;
  word-wrap: break-word !important;
  word-break: break-all !important;
  display: flex;
  position: relative;
  margin-top: 0.5rem;
  //height: 24px !important;
}

.react-grid-table {
  word-wrap: break-word !important;
  word-break: break-all !important;
  white-space: pre-wrap !important;
  table-layout: fixed;
  width: 100%;
  .react-grid-header-item {
    // border: 1px solid #025c71;
    // border-radius: 0.25rem;
    border-bottom: 6px double $table-border-blue;
    margin-bottom: 6px;
    font-size: 1rem;
  }
  .react-grid-item {
    color: #636f83;
    background-color: inherit;
    border-width: 100%;

    padding-right: 2px;
    margin-left: 2px;
    margin-top: 2px;
    border-radius: 0.25rem;
    text-align: left;
    font-size: 1rem;
    vertical-align: middle;
    align-content: top;
  }
}
tr .react-grid-table .react-grid-item {
  .react-grid-cell-item {
    vertical-align: top !important;
  }
}
.grid-layout-draggable {
  color: #636f83;
  background-color: #d22811 !important;
  padding: 0px;
  margin-left: 2px;
  margin-top: 2px;
  border-radius: 0.25rem;
  text-align: left;
  font-size: 14px;
  vertical-align: middle;
  align-content: center;
}
.switch-toggle-custom {
  position: relative !important;
  top: 4px !important;
  margin-right: 10px !important;
}

.tooltip-actual-header {
  text-transform: uppercase;
  background-color: 'darkgrey';
  padding: 10px;
}

.custom-invalid-field-feedback {
  width: 100%;
  margin-top: 0.25rem;
  color: #e55353;
  position: relative;
  top: -10px;
  margin-bottom: 15px;
}

.br-0 > * {
  border-radius: 0 !important;
}

.smaller {
  font-size: 90%;
}

@media (max-width: 1200px) {
  .smaller {
    font-size: 80%;
  }
}

@media (max-width: 900px) {
  .smaller {
    font-size: 70%;
    transform: rotate(270deg);
  }
}

// .tooltip-actual {
//     background-color: "red";
//     padding: 10px;
// }
.languages-icon {
  border-radius: 50%;
  width: 22px;
  height: 22px;
}
.shift-bar-hour-display {
  font-size: 75%;
  position: relative;
  top: 4px;
}

.float-left-important {
  float: left !important;
  margin-left: 0.25rem !important;
  margin-right: 0.5rem !important;
}

.v-hidden {
  visibility: hidden !important;
}

.the-invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e55353;
}

.color-black {
  color: black !important;
}

.permission-table-item {
  background-color: hsl(0, 0%, 90%);
  border-radius: 3px;
  color: hsl(0, 0%, 20%);
  font-size: 80%;
  padding: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  margin: 3px;
}

//fix for the coreui template issue
.p-0offset-sm-6 {
  @extend .p-0;
  @extend .offset-sm-6;
}

.user-role-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.user-role-item {
  min-width: 350px;
}

.mh100 {
  min-height: 100px;
}

.or-tile {
  color: #025c71;
  padding: 10px;
  border: 1px solid #025c71;
  border-radius: 0.25rem;
  text-align: center;
}

.form-title {
  color: #025c71;
  padding: 10px;
  // border: 1px solid #025c71;
  // border-radius: .25rem;
  text-align: left;
  font-size: x-large;
  font-weight: 400;
}

.multi-level-chart-row {
  height: 95px;
  display: flex;
}

.multi-level-chart-column-label {
  text-align: center;
  font-size: 15px;
  margin-top: 18px;
  margin-bottom: 3px;
}

.multi-level-chart-column-chart {
  // flex-basis: calc(100% - 200px);
  flex-grow: 1;
}

.multi-level-chart-row canvas {
  width: 100% !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.pleft15 {
  padding-left: 15px;
}

.card-header {
  background-color: #d3d6db !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.switch-toggle-custom.top1 {
  position: relative !important;
  top: 1px !important;
  left: -10px !important;
  margin-right: 2px !important;
}

.switch-toggle-custom.report-table {
  position: relative !important;
  top: 3px !important;
  left: 0 !important;
  margin-right: 0 !important;
}

.vertical-align-middle td {
  vertical-align: middle !important;
}

.mb05rem {
  margin-bottom: 0.5rem !important;
}

.min-h-500 {
  min-height: 500px !important;
}

.max-h-300 {
  max-height: 300px !important;
  overflow-y: auto;
}

.form-superscript {
  color: #025c71;
  text-align: top;
  font-size: small;
  font-weight: 400;
}

.form-message {
  color: #025c71;
  text-align: center;
  font-size: large;
  text-align: center;
}
.form-message-left {
  color: #025c71;
  text-align: left;
  font-size: large;
}
.form-message-boxed {
  color: #025c71;
  padding: 10px;
  border: 1px solid #025c71;
  border-radius: 0.25rem;
  text-align: center;
  font-size: large;
}
.form-message-boxed-left {
  box-sizing: border-box;
  min-width: 100%;
  color: #025c71;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #025c71;
  border-radius: 0.25rem;
  text-align: left;
  font-size: large;
}
.form-message-boxed-center {
  box-sizing: border-box;
  color: #025c71;
  padding: 10px;
  border: 1px solid #025c71;
  border-radius: 0.25rem;
  text-align: center;
  font-size: normal;
}

.form-message-ital {
  color: #025c71;
  text-align: center;
  font-size: large;
  font-style: italic;
}
.form-message-ital-boxed {
  color: #025c71;
  padding: 3px;
  border: 1px solid #025c71;
  border-radius: 0.25rem;
  color: #025c71;
  text-align: center;
  font-size: large;
  font-style: italic;
}

.w600 {
  width: 600px !important;
  margin: 0 auto !important;
  padding: 0 !important;
  border-radius: 0 !important;
}

.p025rem {
  padding: 0.25rem !important;
}
.form-message-nm {
  color: #025c71;
  font-size: 14px;
}
.form-message-nm-u {
  color: #025c71;
  font-size: 14px;
  text-decoration: underline;
  text-align: center;
  vertical-align: middle;
}
.form-message-padded {
  color: #025c71;
  background-color: #ffffff;
  border: 0px solid #025c71;
  text-align: center;
  font-size: normal;
  text-align: center;
  margin-left: 70px;
  margin-right: 70px;
}
.form-message-padded-start {
  color: #025c71;
  text-align: center;
  font-size: normal;
  text-align: center;
  margin-right: 50px;
}
.selector-narrow {
  color: #025c71;
  margin: 3px;
  // padding: 3px;
  // // border: 1px solid #025c71;
  // border-radius: .25rem;
  // color: #025c71;
  // text-align: center;
  // font-size: normal;
  // font-style: italic;
  max-width: 20%;
}

.form-OR-text {
  color: #025c71;
  background-color: #ffffff;
  border: 0px solid #025c71;
  font-size: medium;
  font-weight: bold;
  text-align: center;
  justify-content: center;
  // margin-left: 70px;
  //margin-right: 70px;
}
.left-margin {
  margin-left: 20px;
}
.border-radius-right-only {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.col-border {
  border-right: 1px solid #025c71;
}

.card-header-light {
  background-color: #ffffff !important;
  font-size: 16px !important;
  font-weight: bold !important;
}
.card-header-light-tc {
  background-color: #ffffff !important;
  border: 1px solid #025c71;
  font-size: 14px !important;
  text-decoration: underline !important;
}
.top-border-only {
  border-top-color: #e1e5ec !important;
  border-top-width: 2px !important;
  border-top-style: solid !important;
}

.primary-font-italic {
  font-size: medium;
  font-style: italic;
  font-weight: bold;
  color: #025c71;
}
.primary-font-italic-end {
  display: flex;
  font-size: 11pt;
  font-style: italic;
  font-weight: bold;
  text-align: end;
  justify-content: flex-end;
  margin-right: 6px;
}
.force-right {
  align-items: right !important;
}
.full-width {
  width: 100%;
  flex-wrap: nowrap;
  justify-content: center;
}

.force-center {
  align-items: center !important;
}

.spinner-border-ref {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid;
  border-right: 0.25em solid transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.item-border {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  border: 1px solid #5c6873;
  padding: 4px;
  margin: 4px;
}
.collapse-chevron {
  float: right;
  // margin-right: 2px;
  vertical-align: middle;
  // padding: 4px;
}
.header-background-boxed-normal {
  color: #636f83;
  background-color: #f0f3f5 !important;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  text-align: left;
  font-size: 14px;
  vertical-align: middle;
}
.header-content {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 2px;
  padding-right: 2px;
}
.header-font {
  color: #636f83;
  // padding: 4px;
  // margin-left: 2px;
  font-size: 14px;
  text-align: left;
  font-weight: lighter;
}
.header-background-normal {
  color: #636f83;
  background-color: #f0f3f5 !important;
  padding: 0px;
  margin-left: 2px;
  margin-top: 2px;
  border-radius: 0.25rem;
  text-align: left;
  font-size: 14px;
  vertical-align: middle;
  align-content: center;
}
.header-background-boxed {
  color: #636f83;
  background-color: #f0f3f5 !important;
  padding: 4px;
  margin: 0px;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  text-align: left;
  align-content: center;
  vertical-align: middle;
}
.header-background-gray {
  color: #636f83;
  background-color: #f0f3f5 !important;
  padding: 0px;
  margin: 0px;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  text-align: left;
  vertical-align: middle;
}
.header-error {
  color: #d60b0b;
  background-color: #f0f3f5 !important;
  padding: 0px;
  margin: 0px;
  border: 0px solid #e4e7ea;
  font-size: 14px;
  font-weight: bold;
  border-radius: 0.25rem;
  text-align: left;
  vertical-align: middle;
}
.error-text {
  color: #d60b0b;
  background-color: #f0f3f5 !important;
  padding: 0px;
  margin: 0px;
  border: 0px solid #d60b0b;
  font-size: 12px;
  font-weight: normal;
  text-align: left;
  vertical-align: middle;
}
.column-text-centered {
  color: #025c71;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}

.p-icon-sidebar {
  width: 14rem;
  height: 5rem;
  font-size: 10rem;
  background-color: #fff;
}
.wellness-check-top {
  margin-bottom: 0.5rem;
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  padding: 0.25rem;
}
.fit-svg {
  box-sizing: border-box;
}
// .btn-secondary.disabled,
// .btn-secondary:disabled {
//   color: #4f5d73;
//   background-color: $pathspot-secondary;
//   border-color: $pathspot-secondary;
// }
// .btn-secondary {
//   color: #4f5d73;
//   background-color: $pathspot-secondary;
//   border-color: $pathspot-secondary;
// }
.td-row {
  // display: inline-block;
  // vertical-align: middle;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
}
.tab-card-header {
  background-color: #fff !important;
  font-size: 16px !important;
  font-weight: bold !important;
  border-bottom: #fff;
}
.reporting-tabs {
  border: 1px solid $pathspot-primary;
  border-top: 1px solid transparent;
  margin-top: -13px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-link {
  color: $pathspot-primary;
  background-color: #fff;
  border-color: $pathspot-primary $pathspot-primary $pathspot-primary;
  border-bottom: 1px solid transparent;
}
.nav-tabs .nav-link {
  border-top: 1px solid $pathspot-primary;
  border-left: 1px solid $pathspot-primary;
  border-right: 1px solid $pathspot-primary;
  border-bottom: 1px solid $pathspot-primary;
  color: $pathspot-gray;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs {
  border-bottom: 1px solid $pathspot-primary;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -5px;
  border-color: $pathspot-primary;
}
.p-badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  text-align: center;
  width: 90%;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  color: $pathspot-white;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .p-badge {
    transition: none;
  }
}

.p-badge-review {
  @extend .p-badge;
  background-color: $review;
}

.p-badge-warning {
  @extend .p-badge;
  background-color: $warning;
}

.p-badge-complete {
  @extend .p-badge;
  background-color: $complete;
}

.p-badge-error {
  @extend .p-badge;
  background-color: $error;
}
.p-badge-ready {
  @extend .p-badge;
  background-color: $ready;
}

.p-badge-secondary {
  @extend .p-badge;
  background-color: $secondary;
}
.p-badge-success {
  @extend .p-badge;
  background-color: #2eb85c;
}
.p-badge-danger {
  @extend .p-badge;
  background-color: #e55353;
}
.my-sidebar {
  display: 0 !important;
}
.my-sidebar {
  // color: $pathspot-secondary;
  .c-sidebar-nav-item {
    :hover {
      background-color: #00a29b;
    }
  }
  .c-sidebar-nav-dropdown {
    :hover {
      background-color: #00a29b;
    }
  }
  background-color: $pathspot-primary;
  .c-sidebar-nav-dropdown.c-show {
    :hover {
      background: transparent;
    }

    .c-sidebar-nav-dropdown-items {
      .c-sidebar-nav-item {
        :hover {
          background-color: #00a29b;
        }
      }
    }
  }
  .c-sidebar-nav-dropdown {
    .c-show {
      :hover {
        background: transparent;
      }
    }
    .c-sidebar-nav-item {
      .c-sidebar-nav-link {
        padding-left: 75px;
      }
    }
  }
}
.coreui-margin {
  color: $pathspot-grey !important;
  font-size: 14px !important;
  font-weight: normal !important;
  margin-right: -15px !important;
  margin-left: -15px !important;
}
.c-sidebar .c-sidebar-brand {
  color: #fff;
  background: $pathspot-white;
}
.c-switch-label .c-switch-slider::before,
.c-switch-label .c-switch-slider::before {
  z-index: 0 !important;
}
.my-sidebar {
  display: 0 !important;
}
.my-sidebar {
  .c-sidebar-nav-dropdown {
    .c-sidebar-nav-item {
      .c-sidebar-nav-link {
        padding-left: 75px;
      }
    }
  }
}
.c-switch-label .c-switch-slider::before,
.c-switch-label .c-switch-slider::before {
  z-index: 0 !important;
}
.coreui-margin {
  color: $pathspot-grey !important;
  font-size: 14px !important;
  font-weight: normal !important;
  margin-right: -15px !important;
  margin-left: -15px !important;
}
.c-sidebar-brand {
  min-height: 104px;
}
.c-sidebar .c-sidebar-brand {
  color: #fff;
  background: $pathspot-white;
}
.c-header .c-subheader {
  border-bottom: 1px solid $pathspot-primary-05p;
}

// .c-header-nav {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   min-height: 2rem;
//   padding: 0;
//   margin-bottom: 0;
//   list-style: none;
// }
@media all and (max-width: $xxs) {
  .c-header-brand {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    min-height: 2.5rem;
    margin-top: 0.5rem;
    margin-left: -0.75rem !important;

    transition: width 0.3s;
  }
  .c-header-nav {
    // display: flex !important;
    // width: 50%;
    // flex-direction: column;
    // align-self: flex-end;
    // align-items: flex-end;
    // justify-content: center;
    display: inline-flex;
    flex-direction: row;
    min-height: 1rem;
    font-size: 0.75rem;
    align-items: flex-end;
    justify-content: flex-end;
    // align-self: flex-end;
    width: 100%;
    min-height: 1rem;
    margin-top: -0.75rem;
    transition: width 0.3s;
  }
  .d-md-down-none {
    display: inline-flex !important;
  }
  // .c-header-toggler {
  //   font-size: 1rem;
  //   background-color: transparent;
  //   border: 0;
  //   border-radius: 0.25rem;
  // }
  .c-header-toggler-icon {
    display: flex;
    height: 1.5rem;
    flex-shrink: 1;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
  }
}

.status-modal {
  display: flex !important;
  flex-wrap: nowrap;
  flex-direction: column;
  // flex-shrink: 0;
  // flex-grow: 0;
  justify-content: space-evenly;
  align-items: center;
  width: 100%; //12.8rem;
  height: auto; //9.6rem !important;
  // margin: 0.5rem;
  // margin-bottom: 0.5rem;
  border-color: #ffffff;
  border-radius: 1.5rem;
  border-style: solid;
  border-width: 0.15rem;
  background-color: #ffffff;
}
.status-modal-text-alignment {
  display: inline-flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90%;
  align-items: center;
  justify-content: flex-start;
  border: 0px dashed #e72a2a;
  margin-top: 1rem;
  align-items: center;
  border: 0px solid #1e5daa;
}
.status-modal-text {
  display: inline-flex !important;
  align-self: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: var(--titleFont, 1.14rem);
  font-weight: bolder;
}

.status-modal-logo-alignment {
  display: inline-flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 40%;
  align-items: center;
  justify-content: center;
  border: 0px dashed #e72a2a;
  margin-top: 2rem;
  margin-bottom: 4rem;
  align-items: center;
}

.width-eighth {
  width: 1.25%;
}
.location-phone-group {
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.location-country-code {
  width: 30% !important;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
}
.location-phone-number {
  display: inline-flex;
  justify-content: flex-end;
  width: 68% !important;
}

$pathspot-primary: #025c71;
$pathspot-secondary: #00a199;
$pathspot-success: #00a29b;
$pathspot-warning: #a4cf59;
$pathspot-info: #a55095;
$pathspot-danger: #681823;
$pathspot-blue: #025c71;
$pathspot-blue-50: #509cad;
$pathspot-teal: #00a29b;
$pathspot-teal-50: #72b6b2;
$pathspot-light-green: #a4cf59;
$pathspot-lavender: #a55095;
$pathspot-lavender-50: #d492c8;
$pathspot-grey: #636f83;
$pathspot-grey-9: #999999;
$pathspot-grey-dark: #515967;
$pathspot-grey-light: #818a98;
$pathspot-white: #ffffff;
$pathspot-off-white: #f4f3ee;
$pathspot-bg-white: #cfd7e3;
$pathspot-black: #000000;
$pathspot-red: #8d1928;

.p-icon {
  display: inline-block;
  text-align: center;
}

.p-icon-sm {
  @extend .p-icon;
  width: 0.875rem;
  height: 0.875rem;
  font-size: 0.875rem;
}

.p-icon-lg {
  @extend .p-icon;
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}

.p-icon-xl {
  @extend .p-icon;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
}

.p-icon-2xl {
  @extend .p-icon;
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
}

.p-icon-3xl {
  @extend .p-icon;
  width: 3rem;
  height: 3rem;
  font-size: 3rem;
}

.p-icon-4xl {
  @extend .p-icon;
  width: 4rem;
  height: 4rem;
  font-size: 4rem;
}
.p-icon-5xl {
  @extend .p-icon;
  width: 5rem;
  height: 5rem;
  font-size: 5rem;
}
.p-icon-6xl {
  @extend .p-icon;
  width: 6rem;
  height: 6rem;
  font-size: 6rem;
}
.p-icon-7xl {
  @extend .p-icon;
  width: 7rem;
  height: 7rem;
  font-size: 7rem;
}
.p-icon-8xl {
  @extend .p-icon;
  width: 8rem;
  height: 8rem;
  font-size: 8rem;
}
.p-icon-9xl {
  @extend .p-icon;
  width: 9rem;
  height: 9rem;
  font-size: 9rem;
}
.p-icon-fallback {
  @extend .p-icon;
  width: 20rem;
  height: 20rem;
  font-size: 20rem;
}
.p-icon-sidebar {
  @extend .p-icon;
  width: 14rem;
  height: 5rem;
  font-size: 10rem;
  background-color: $pathspot-white;
}
.p-icon-button-header-right {
  display: inline-block;
  margin-right: 2px;
}
